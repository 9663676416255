import TinyMceParser from './TinyMceParser'
import TinyMceSnippet from './TinyMceSnippet'

const TINYMCE_SETTINGS_STYLE_FORMATS = [
  {
    title: 'Headers',
    items: [
      { title: 'Header 2', format: 'h2' },
      { title: 'Header 3', format: 'h3' },
      { title: 'Header 4', format: 'h4' },
      { title: 'Header 5', format: 'h5' },
      { title: 'Header 6', format: 'h6' },
      { title: 'Paragraph', format: 'p' }
    ]
  },
  {
    title: 'Inline',
    items: [
      { title: 'Bold', icon: 'bold', format: 'bold' },
      { title: 'Italic', icon: 'italic', format: 'italic' },
      { title: 'Underline', icon: 'underline', format: 'underline' },
      { title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough' },
      { title: 'Superscript', icon: 'superscript', format: 'superscript' },
      { title: 'Subscript', icon: 'subscript', format: 'subscript' }
    ]
  }
]

const getCustomButtons = () => {
  return {
    embedded_image: {
      id: 'custom_button_embedded_image',
      icon: 'custom fas fa-image',
      snippet: TinyMceSnippet.image(),
      tooltip: 'Obrázok',
      loaded: false,
      template_source: ''
    },
    embedded_livebox: {
      id: 'custom_button_embedded_livebox',
      icon: 'custom fas fa-video',
      snippet: TinyMceSnippet.livebox(),
      tooltip: 'Video',
      loaded: false,
      template_source: ''
    },
    embedded_youtube: {
      id: 'custom_button_embedded_youtube',
      icon: 'custom fab fa-youtube',
      snippet: TinyMceSnippet.youtube(),
      tooltip: 'Youtube video',
      loaded: false,
      template_source: ''
    },
    quote: {
      id: 'custom_button_quote',
      icon: 'custom fas fa-quote-left',
      snippet: TinyMceSnippet.quote(),
      tooltip: 'Citát',
      loaded: false,
      template_source: ''
    },
    poll: {
      id: 'custom_button_poll',
      icon: 'custpm fas fa-chart-bar',
      snippet: TinyMceSnippet.poll(),
      tooltip: 'Anketa',
      loaded: false,
      template_source: ''
    },
    related_article: {
      id: 'custom_button_related_article',
      icon: 'custom far fa-file-alt',
      snippet: TinyMceSnippet.relatedArticle(),
      tooltip: 'Súvisiaci článok',
      loaded: false,
      template_source: ''
    },
    related_disease_article: {
      id: 'custom_button_related_disease_article_embed',
      icon: 'custom fas fa-stethoscope',
      snippet: TinyMceSnippet.relatedDiseaseArticle(),
      tooltip: 'Súvisiace ochorenie',
      loaded: false,
      template_source: ''
    },
    html_embed: {
      id: 'custom_button_html_embed',
      icon: 'custom fa fa-code',
      snippet: TinyMceSnippet.htmlEmbed(),
      tooltip: 'HTML kód',
      loaded: false,
      template_source: ''
    },
    page_break: {
      id: 'custom_button_page_break',
      icon: 'pagebreak',
      snippet: TinyMceSnippet.pageBreak(),
      tooltip: 'Zalomenie strany',
      loaded: false,
      template_source: ''
    },
    paid_content_break: {
      id: 'custom_button_paid_content_break',
      icon: 'custom fa fa-lock',
      snippet: TinyMceSnippet.paidContentBreak(),
      tooltip: 'Bod uzamknutia',
      loaded: false,
      template_source: ''
    },
    infobox: {
      id: 'custom_button_infobox_embed',
      icon: 'custom fas fa-sticky-note',
      snippet: TinyMceSnippet.infobox(),
      tooltip: 'Infobox',
      loaded: false,
      template_source: ''
    },
    sport_table: {
      id: 'custom_button_sport_table_embed',
      icon: 'custom fas fa-futbol',
      snippet: TinyMceSnippet.sportTable(),
      tooltip: 'Športové tabuľky',
      loaded: false,
      template_source: ''
    },
    sport_online: {
      id: 'custom_button_sport_online_embed',
      icon: 'custom fas fa-circle',
      snippet: TinyMceSnippet.sportOnline(),
      tooltip: 'Online zápas',
      loaded: false,
      template_source: ''
    },
    car_table: {
      id: 'custom_button_car_table_embed',
      icon: 'custom fa fa-car',
      snippet: TinyMceSnippet.carTable(),
      tooltip: 'Tabuľka vozidla',
      loaded: false,
      template_source: ''
    },
    gallery_link: {
      id: 'custom_button_gallery_link_embed',
      icon: 'custom far fa-images',
      snippet: TinyMceSnippet.galleryLink(),
      tooltip: 'Odkaz na galériu',
      loaded: false,
      template_source: ''
    }
  }
}

const getConfig = () => {
  return {
    element_format: 'html',
    entity_encoding: 'named',
    entities: '160,nbsp',
    theme: 'modern',
    // inline: true,
    height: 300,
    autoresize_min_height: 300,
    skin_url: '/tinymce/skins/lightgray',
    content_css: [
      '/tinymce/main.css',
      '/tinymce/font-awesome.css'
    ],
    language_url: '/tinymce/langs/sk.js',
    valid_children: '-p[div],-div[p]',
    plugins: [
      'autoresize',
      'link paste template advlist',
      'lists fullscreen wordcount',
      'table'
    ],
    default_link_target: '_blank',
    paste_as_text: true,
    paste_data_images: false,
    style_formats: TINYMCE_SETTINGS_STYLE_FORMATS,
    menubar: false,
    advlist_bullet_styles: 'default',
    advlist_number_styles: 'default,lower-alpha',
    toolbar: [
      'undo redo | cut copy paste selectall | styleselect | custom_headline_2 custom_headline_3 | bold italic underline | ' +
      'custom_button_unordered_list numlist | link | fullscreen',
      'custom_button_embedded_image custom_button_embedded_livebox custom_button_embedded_youtube custom_button_quote custom_button_poll ' +
      'custom_button_related_article custom_button_related_disease_article_embed custom_button_html_embed custom_button_page_break custom_button_infobox_embed custom_button_sport_table_embed custom_button_sport_online_embed custom_button_car_table_embed custom_button_gallery_link_embed custom_button_paid_content_break'
    ],
    paste_preprocess: function (plugin, args) {
      let content = args.content
      content = TinyMceParser.removeElement(content, 'pre', true)
      content = TinyMceParser.removeSuccElement(content, 'h1', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h2', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h3', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h4', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h5', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h6', 'a', true)
      args.content = content
    },
    setup: editor => {
      editor.addButton('custom_button_unordered_list', {
        text: '',
        icon: 'bullist',
        tooltip: 'Bullet list',
        onclick: function () {
          editor.execCommand('InsertUnorderedList')
        }
      })
      editor.addButton('custom_headline_2', {
        text: '',
        icon: ' mdi mdi-format-header-2',
        tooltip: 'Headline 2',
        onclick: function () {
          editor.execCommand('FormatBlock', false, 'h2')
        }
      })
      editor.addButton('custom_headline_3', {
        text: '',
        icon: ' mdi mdi-format-header-3',
        tooltip: 'Headline 3',
        onclick: function () {
          editor.execCommand('FormatBlock', false, 'h3')
        }
      })
      const customButtons = getCustomButtons()
      for (const customButtonKey in customButtons) {
        editor.addButton(customButtons[customButtonKey].id, {
          text: false,
          tooltip: customButtons[customButtonKey].tooltip,
          icon: customButtons[customButtonKey].icon,
          onclick: function () {
            editor.insertContent(customButtons[customButtonKey].snippet)
          }
        })
      }
    }
  }
}

export default {
  getConfig
}

<template>
  <section>
    <div class="main-heading-text">
      {{ $t('article.gallery') }}
    </div>
    <MediaUploadButton
      v-if="!disabled"
      @set-media="addMediaToGallery"
      multiple
      :button-text="$t('buttons.upload_photo_from_computer')"
      data-test="recipe_btn_select_gallery_from_pc"
    />
    <MediaSelectButton
      v-if="!disabled"
      @set-media="addMediaToGallery"
      multiple
      :button-text="$t('buttons.select_photo_from_bank')"
      data-test="recipe_btn_select_gallery_from_dam"
    />
    <MediaUrlButton
      v-if="!disabled"
      @set-media="addMediaToGallery"
      type-of-btn="url"
      :btn-text="$t('buttons.upload_photo_from_url')"
      data-test="recipe_btn_select_gallery_from_url"
    />
    <MediaUrlButton
      v-if="!disabled"
      @set-media="addMediaToGallery"
      type-of-btn="embed"
      :btn-text="$t('buttons.upload_photo_from_url_social')"
      data-test="recipe_btn_select_gallery_from_url"
    />
    <MediaEditButton
      v-if="!disabled"
      :selectedMedia="recipe.expanded.gallery"
    />
    <Gallery
      :disabled="disabled"
      class="gallery"
      parent-store-name="recipe"
      :gallery="recipe.expanded.gallery"
      @set-hero-image="setImageFromGallery"
    />
  </section>
</template>

<script>
import Gallery from '@/components/article/ArticleGallery'
import MediaService from '@/services/media/MediaService'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditButton from '@/components/shared/MediaEditButton'

export default {
  name: 'RecipeFormGalleryTab',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Gallery,
    MediaEditButton,
    MediaUrlButton,
    MediaUploadButton,
    MediaSelectButton
  },
  methods: {
    checkIfAssetExistInGallery (asset) {
      let selected = false
      this.recipe.expanded.gallery.forEach(media => {
        if (media.damMediaEmbed.damId === asset.damMediaEmbed.damId) {
          selected = true
        }
      })
      return selected
    },
    addMediaToGallery (medias) {
      medias.forEach(media => {
        if (this.checkIfAssetExistInGallery(media) === false) {
          MediaService.createMedia(media)
            .then(newMedia => {
              this.recipe.expanded.gallery.push(newMedia)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    setImageFromGallery (medias) {
      medias.forEach(media => {
        MediaService.createMedia(media)
          .then(newMedia => {
            this.setImage([newMedia])
          })
          .catch((error) => {
            console.log(error)
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.gallery {
  margin-top: rem(10px);
}
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
</style>

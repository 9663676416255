<template>
  <section>
    <div class="main-heading-text">
      {{ $t('recipe.ingredient_groups') }}
    </div>
    <div
      v-for="(ingredientGroup, index) in recipe.ingredientGroups"
     :key="`ingredient_group_${index}`"
      class="ingredient-groups"
    >
      <div class="ingredient-groups__heading">
        <div class="ingredient-groups__heading__text">
          {{ $t('recipe.ingredient_group') }}
        </div>
        <ModuleFormButton
          v-if="!disabled && (recipe.ingredientGroups.length > 1)"
          icon="delete"
          @click="removeIngredientGroup(index)"
        />
      </div>
      <Input
        v-model="ingredientGroup.title"
        :id="`recipe_ingredient_group_${index}`"
        :label="$t('recipe.ingredient_title')"
        :placeholder="$t('recipe.ingredient_title_placeholder')"
        :disabled="disabled"
      />

      <div
        v-for="(ingredient, idx) in ingredientGroup.ingredients"
        :key="`ingredient_${index}_${idx}`"
        class="ingredients"
      >
        <div
          v-if="ingredientGroup.ingredients.length > 1"
          class="ingredients__heading"
        >
          <ModuleFormButton
            class="ingredients__heading__delete-button"
            v-if="!disabled"
            icon="delete"
            @click="removeIngredient(ingredientGroup, idx)"
          />
        </div>
        <div class="ingredients__inputs">
          <Input
            class="ingredients__inputs__title"
            v-model="ingredient.title"
            :id="`recipe_ingredient_title_${index}_${idx}`"
            :label="$t('recipe.ingredient')"
            required
            @blur="$v.recipe.ingredientGroups.$each[index].ingredients.$each[idx].title.$touch()"
            :error="$v.recipe.ingredientGroups.$each[index].ingredients.$each[idx].title.$error"
            :disabled="disabled"
          />
          <Input
            class="ingredients__inputs__amount"
            v-model="ingredient.amount"
            :id="`recipe_ingredient_amount_${index}_${idx}`"
            :label="$t('recipe.amount')"
            :disabled="disabled"
          />
          <Select
            class="ingredients__inputs__unit"
            v-model="ingredient.unit"
            :id="`recipe_ingredient_unit_${index}_${idx}`"
            :options="units"
            no-empty-value
            :label="$t('recipe.unit')"
            :disabled="disabled"
          />
        </div>
        <Input
          v-model="ingredient.shopUrl"
          :id="`recipe_ingredient_url_${index}_${idx}`"
          :label="$t('buttons.upload_from_url')"
          placeholder="https://"
          @blur="$v.recipe.ingredientGroups.$each[index].ingredients.$each[idx].shopUrl.$touch()"
          :error="$v.recipe.ingredientGroups.$each[index].ingredients.$each[idx].shopUrl.$error"
          :disabled="disabled"
        />
      </div>
      <button
        v-if="!disabled"
        class="add-button"
        :title="$t('recipe.add_ingredient')"
        @click="addIngredient(index)"
      >
        <PlusIcon class="add-button__icon" />
        {{ $t('recipe.add_ingredient') }}
      </button>
    </div>
    <button
      v-if="!disabled"
      class="add-button ingredient-groups__add-button"
      :title="$t('recipe.add_ingredient_group')"
      @click="addIngredientGroup()"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('recipe.add_ingredient_group') }}
    </button>
    <div class="header-text">
      {{ $t('recipe.ingredient_urls') }}
    </div>
    <Input
      v-model="recipe.shopUrl"
      id="recipe_shop_url"
      :label="$t('recipe.shop')"
      placeholder="https://"
      :disabled="disabled"
    />
    <Input
      v-model="recipe.discountUrl"
      id="recipe_discount_url"
      :label="$t('recipe.discount')"
      placeholder="https://"
      :disabled="disabled"
    />
  </section>
</template>

<script>

import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import RecipeModel from '@/model/RecipeModel'
import { required, url } from 'vuelidate/lib/validators'
import ModuleFormButton from '@/components/shared/ModuleFormButton'

export default {
  name: 'RecipeFormIngredientsLeftColumn',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormButton,
    PlusIcon,
    Input,
    Select
  },
  computed: {
    units () {
      return this.$store.getters['recipe/units']
    }
  },
  validations: {
    recipe: {
      ingredientGroups: {
        $each: {
          ingredients: {
            $each: {
              title: {
                required
              },
              shopUrl: {
                url
              }
            }
          }
        }
      }
    }
  },
  methods: {
    addIngredientGroup () {
      this.recipe.ingredientGroups.push(this._.cloneDeep(RecipeModel.ingredientGroups[0]))
    },
    removeIngredientGroup (index) {
      this.recipe.ingredientGroups.splice(index, 1)
    },
    addIngredient (index) {
      this.recipe.ingredientGroups[index].ingredients.push(
        this._.cloneDeep(RecipeModel.ingredientGroups[0].ingredients[0])
      )
    },
    removeIngredient (ingredientGroup, idx) {
      ingredientGroup.ingredients.splice(idx, 1)
    }
  },
  mounted () {
    this.$store.dispatch('recipe/fetchUnits')
  }
}
</script>

<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.header-text {
   @include font(500 16px "Roboto");
   color: #465674;
   margin-top: rem(8px);
   margin-bottom: rem(8px);
 }
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: 1rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}
.ingredient-groups {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);
  &__heading {
    display: flex;
    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(700 16px "Roboto");
      color: #465674;
      text-transform: uppercase;
      margin-bottom: rem(10px);
    }
  }
  &__add-button {
    width: 100%;
  }
}
.ingredients {
  background-color: #F2F3F7;
  margin-top: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);
  &__heading {
    text-align: right;
    &__delete-button {
      margin-bottom: rem(-20px);
    }
  }
  &__inputs {
    display: flex;
    gap: 2%;
    &__title {
      flex: 0 0 49%;
    }
    &__amount, &__unit {
      flex: 0 0 24%;
    }
  }
}
</style>

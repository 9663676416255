<template>
  <section>
    <div class="main-heading-text">
      {{ $t('recipe.instructions') }}
    </div>
    <div
      v-for="(step, index) in recipe.instructions"
      :key="`instruction_${index}`"
      class="instructions"
    >
      <div class="instructions__heading">
        <div class="instructions__heading__text">
        {{ index + 1 }}. {{ $t('recipe.step') }}
        </div>
        <ModuleFormButton
          v-if="!disabled && (recipe.instructions.length > 1)"
          icon="delete"
          @click="removeStep(index)"
        />
      </div>
      <ArticleRichTextEditor
        :ref="`instructions_step_${index}`"
        v-model="recipe.instructions[index]"
        :id="`instructions_step_${index}`"
        :other-options="richTextEditorConfig"
        :enabledEmbedButtons="enabledEmbedButtons"
        rows="10"
        :readonly="disabled"
      />
    </div>

    <button
      v-if="!disabled"
      class="add-button"
      :title="$t('recipe.add_step')"
      @click="addStep()"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('recipe.add_step') }}
    </button>
  </section>
</template>

<script>
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import TinyMceArticleConfig from '@/services/tinymce/TinyMceArticleConfig'
import ArticleRichTextEditor from '@/components/article/ArticleRichTextEditor'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'

export default {
  name: 'RecipeFormInstructionsTab',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ArticleRichTextEditor,
    ModuleFormButton,
    PlusIcon
  },
  data () {
    return {
      richTextEditorConfig: TinyMceArticleConfig.getConfig(),
      enabledEmbedButtons: {
        media: true,
        ooyala: true,
        youtube: true,
        quote: false,
        poll: false,
        relatedArticle: false,
        relatedDiseaseArticle: false,
        html: true,
        pageBreak: false,
        paidContentBreak: false,
        infobox: true,
        galleryLink: true,
        sportTable: false,
        sportOnline: false,
        carTable: false
      }
    }
  },
  methods: {
    addStep () {
      this.recipe.instructions.push('')
    },
    removeStep (index) {
      this.recipe.instructions.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.instructions {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);
  &__heading {
    display: flex;
    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(700 16px "Roboto");
      color: #465674;
      text-transform: uppercase;
      margin-bottom: rem(10px);
    }
  }
}
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  width: 100%;
  margin-top: 1rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}
</style>

<template>
  <app-modal
    :title="$t('article.modal.set_hero_image_header')"
    modal-size="modal-md"
    close-on-click-self
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="text-center">
        <button
          type="button"
          @click="setHeroImage"
          class="btn btn-green"
          data-test="article_create_btn"
        >
          {{ $t('article.modal.yes') }}
        </button>
        <button
          type="button"
          class="btn btn-inverse"
          @click="close"
          data-test="before_route_leave_btn_no"
        >
          {{ $t('article.modal.no') }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'ArticleSetHeroImageModal',
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    close () {
      this.$emit('close')
    },
    setHeroImage () {
      this.$emit('set-hero-image', this.asset)
      this.$emit('close')
    }
  }
}
</script>

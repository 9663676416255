<template>
  <section>
    <Input
      v-model="recipe.title"
      @blur="$v.recipe.title.$touch()"
      :error="$v.recipe.title.$error"
      id="recipe_title"
      required
      :label="$t('recipe.title')"
      :placeholder="$t('recipe.title_placeholder')"
      :disabled="disabled"
    />
    <div class="three-columns">
      <Select
        v-model="recipe.site"
        @blur="$v.recipe.site.$touch()"
        :error="$v.recipe.site.$error"
        :options="sites"
        id="recipe_site"
        :label="$t('modal.site')"
        required
        :disabled="disabled"
        class="three-columns__column"
      />
      <Select
        v-model="recipe.category"
        @blur="$v.recipe.category.$touch()"
        :error="$v.recipe.category.$error"
        :options="categories"
        id="recipe_category"
        :label="$t('modal.category')"
        required
        :disabled="disabled"
        class="three-columns__column"
      />
      <div class="three-columns__column">
        <div class="add-tag-button">
          <CreateTagButton
            v-if="searchedTag"
            :searchedTag="searchedTag"
            @set-tag="addTag"
          />
        </div>
        <div>
          <div class="title-text">
            {{ $t('modal.tags') }}
          </div>
        </div>
        <MultiSelect
          v-model="recipe.expanded.tags"
          :options="tags"
          label="title"
          track-by="id"
          :preselect-first="true"
          :loading="callingAPI"
          :internal-search="false"
          :options-limit="300"
          :limit="10"
          :max-height="600"
          :show-no-results="false"
          open-direction="bottom"
          @search-change="findTag"
          id="recipe_tags"
          class="form-group"
          :disabled="disabled"
        />
      </div>
    </div>
    <Textarea
      v-model="recipe.description"
      disable-form-group
      id="recipe_description"
      :label="$t('modal.description')"
      :rows="5"
      required
      class="form-group"
      @blur="$v.recipe.description.$touch()"
      :error="$v.recipe.description.$error"
      :disabled="disabled"
    />
    <div class="two-columns">
      <Input
        v-model="recipe.spentTime"
        @blur="$v.recipe.spentTime.$touch()"
        :error="$v.recipe.spentTime.$error"
        id="recipe_spentTime"
        :label="$t('recipe.spentTime')"
        type="number"
        :min="0"
        required
        class="two-columns__column"
        :disabled="disabled"
      />
      <Input
        v-model="recipe.portions"
        @blur="$v.recipe.portions.$touch()"
        :error="$v.recipe.portions.$error"
        id="recipe_portions"
        :label="$t('recipe.portions')"
        type="number"
        :min="1"
        required
        class="two-columns__column"
        :disabled="disabled"
      />
    </div>
    <div class="two-columns">
      <Input
        v-model="recipe.price"
        @blur="$v.recipe.price.$touch()"
        :error="$v.recipe.price.$error"
        id="recipe_price"
        type="number"
        :label="$t('recipe.price')"
        right-label="€"
        class="two-columns__column"
        :disabled="disabled"
      />
      <Select
        v-model="recipe.difficulty"
        :id="`recipe_difficulty`"
        :options="difficulties"
        no-empty-value
        :label="$t('recipe.difficulty')"
        class="two-columns__column"
        :disabled="disabled"
      />
    </div>
    <!-- hide till CMS-541 is resolved -->
    <Datepicker
      v-if="false"
      v-model="recipe.publishedSince"
      id="recipe_publishedSince"
      :label="$t('recipe.published_since')"
      :placeholder="$t('recipe.published_since_placeholder')"
      :show-set-now="!disabled"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import MultiSelect from '@/components/form/select/MultiSelect'
import { mapState } from 'vuex'
import { minValue, required } from 'vuelidate/lib/validators'
import CreateTagButton from '@/components/article/ArticleCreateTagButton'

export default {
  name: 'RecipeFormMainTabLeftColumn',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CreateTagButton,
    Select,
    Textarea,
    MultiSelect,
    Input,
    Datepicker
  },
  data () {
    return {
      searchedTag: '',
      tags: []
    }
  },
  validations: {
    recipe: {
      title: {
        required
      },
      description: {
        required
      },
      site: {
        required
      },
      category: {
        required
      },
      spentTime: {
        required,
        minValue: minValue(1)
      },
      portions: {
        required,
        minValue: minValue(1)
      },
      price: {
        minValue: minValue(0)
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    sites () {
      return this.$store.getters['site/enabledSites']()
    },
    categories () {
      return this.$store.getters['recipeCategory/all']
    },
    difficulties () {
      return this.$store.getters['recipe/difficulties']
    }
  },
  methods: {
    addTag (tag) {
      this.recipe.expanded.tags.push(tag)
      this.searchedTag = ''
    },
    findTag (query) {
      this.searchedTag = ''
      this.$store.dispatch('tag/fetchByTitle', { query, view: 'recipe' })
        .then(() => {
          this.tags = this.$store.getters['tag/tagsWithRecipeCount']
          this.searchedTag = query
        })
    }
  },
  mounted () {
    this.$store.dispatch('recipe/fetchDifficulties')
    this.$store.dispatch('recipeCategory/fetchAll')
  }
}
</script>

<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
  padding-top: rem(2px);
}
.two-columns {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__column {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 49%;
    }
  }
}
.three-columns {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__column {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 32%;
    }
  }
}
.add-tag-button {
  position: absolute;
  z-index: 100;
}
</style>

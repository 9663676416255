<template>
  <app-modal
    :title="$t('article.modal.quote_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ $t('article.modal.quote_title') }}</label>
            <textarea v-model.trim="value.title" :rows="5" type="text" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ $t('article.modal.quote_author') }}</label>
            <input v-model.trim="value.author" type="text" class="form-control">
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

export default {
  name: 'EditorQuoteModal',
  data () {
    return {
      value: {
        title: '',
        author: ''
      }
    }
  },
  props: {
    selectedData: {
      type: Object
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    insert () {
      this.$emit('set-data', { title: this.value.title, author: this.value.author })
      this.$emit('close')
    },
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    }
  },
  created () {
    if (this.selectedData) {
      this.value = this.selectedData
    }
  }
}
</script>

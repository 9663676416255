<script>
import ModuleView from '@/components/ModuleView'
import RecipeNewView from './RecipeNewView'

export default {
  name: 'RecipeEditView',
  extends: RecipeNewView,
  components: {
    appModuleView: ModuleView
  },
  async mounted () {
    const previousTabIndex = this.$store.getters['recipe/previousTabIndex']
    this.$refs.recipeForm.tabClick(previousTabIndex)
    this.$store.commit('recipe/setPreviousTabIndex', 0)
    await this.getRecipe()
  }
}
</script>

<template>
  <div data-test="tinymce_editor" :class="editorClasses">
    <textarea :id="id" v-model="content" />
    <button v-if="editorError" class="m-t-5 m-b-5 btn btn-sm btn-secondary" @click="reloadEditor">Oprav editor</button>
    <app-media-select-button
      hide-button
      showPageBreaksCheckbox
      @set-media="addMedia"
      @add-page-breaks="addPageBreaks"
      :multiple="mediaSelectMultiple"
      data-test="article_btn_select_hero_image_from_dam"
    >
    </app-media-select-button>
    <app-media-upload-button
      hide-button
      showPageBreaksCheckbox
      @set-media="addMedia"
      @add-page-breaks="addPageBreaks"
      :multiple="mediaSelectMultiple"
      data-test="article_btn_select_hero_image_from_pc"
    >
    </app-media-upload-button>
    <app-media-url-button
      hide-button
      @set-media="addMedia"
      data-test="article_btn_select_hero_image_from_url"
    >
    </app-media-url-button>
    <app-media-edit-modal
      v-if="mediaEditModal"
      :selectedMedia="mediaToEdit"
      @close="closeMediaEditModal"
      @add-media="updateMediaEmbed"
      @add-page-breaks="addPageBreaks"
    >
    </app-media-edit-modal>
    <app-video-list-modal
      v-if="videoListModal"
      :selectedData="selectedData"
      @set-data="setVideo"
      @close="closeVideoListModal"
    >
    </app-video-list-modal>
    <app-youtube-modal
      v-if="youtubeModal"
      :selectedData="selectedData"
      @close="closeYoutubeModal"
      @set-data="setOoyalaOrYoutubeVideo"
    >
    </app-youtube-modal>
    <app-sport-table-modal
      v-if="sportTableModal"
      :selectedData="selectedData"
      @close="closeSportTableModal"
      @set-data="setSportTableData"
    >
    </app-sport-table-modal>
    <app-gallery-link-modal
      v-if="galleryLinkModal"
      :selectedData="selectedData"
      @close="closeGalleryLinkModal"
      @set-data="setGalleryLinkData"
    >
    </app-gallery-link-modal>
    <app-sport-online-modal
      v-if="sportOnlineModal"
      :selectedData="selectedData"
      @close="closeSportOnlineModal"
      @set-data="setSportOnlineData"
    >
    </app-sport-online-modal>
    <app-article-test-modal
      v-if="carTableModal"
      :multiple="true"
      :maxSelected="3"
      :selectedData="selectedData"
      :discriminators="articleTestDiscriminatorsCarEmbed"
      @close="closeCarTableModal"
      @set-data="setCarTableData"
    >
    </app-article-test-modal>
    <app-editor-quote-modal
      v-if="editorQuoteModal"
      :selectedData="selectedData"
      @close="closeEditorQuoteModal"
      @set-data="setQuote"
    >
    </app-editor-quote-modal>
    <app-editor-html-modal
      v-if="editorHtmlModal"
      :selectedData="selectedData"
      @close="closeEditorHtmlModal"
      @set-data="setHtml"
    >
    </app-editor-html-modal>
    <app-related-article-modal
      v-if="relatedArticleModal"
      :selectedData="selectedData"
      :multiple="relatedArticleModalMultiple"
      @close="closeRelatedArticleModal"
      @set-data="setRelatedArticle"
    >
    </app-related-article-modal>
    <app-related-article-config-modal
      v-if="relatedArticleConfigModal"
      :selectedData="selectedData"
      :siteName="siteName"
      :articleType="articleType"
      @close="closeRelatedArticleConfigModal"
      @set-data="setRelatedArticleConfig"
    >
    </app-related-article-config-modal>
    <app-infobox-create-modal
      v-if="infoboxCreateModal"
      :selectedData="selectedData"
      @close="closeInfoboxCreateModal"
      @set-data="setInfobox"
      @show-infobox-create-modal="showInfoboxCreateModal"
      @show-infobox-list-modal="showInfoboxListModal"
    >
    </app-infobox-create-modal>
    <app-infobox-list-modal
      v-if="infoboxListModal"
      :selectedData="selectedData"
      @close="closeInfoboxListModal"
      @set-data="setInfobox"
      @show-infobox-create-modal="showInfoboxCreateModal"
      @show-infobox-list-modal="showInfoboxListModal"
    >
    </app-infobox-list-modal>
    <app-poll-create-modal
      v-if="pollCreateModal"
      :selectedData="selectedData"
      @close="closePollCreateModal"
      @set-data="setPoll"
      @show-poll-create-modal="showPollCreateModal"
      @show-poll-list-modal="showPollListModal"
    >
    </app-poll-create-modal>
    <app-poll-list-modal
      v-if="pollListModal"
      :selectedData="selectedData"
      @close="closePollListModal"
      @set-data="setPoll"
      @show-poll-create-modal="showPollCreateModal"
      @show-poll-list-modal="showPollListModal"
    >
    </app-poll-list-modal>
  </div>
</template>

<script>
// Import TinyMCE
import tinymce from 'tinymce'

// A theme is also required
import 'tinymce/themes/modern/theme'

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/print'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/bbcode'
import 'tinymce/plugins/code'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/help'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/save'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/table'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/visualchars'

import 'tinymce/skins/lightgray/skin.min.css'

// Nmh custom settings
import TinyMceSnippet from '../../services/tinymce/TinyMceSnippet'
import MediaSelectButton from '../shared/MediaSelectButton'
import MediaUploadButton from '../shared/MediaUploadButton'
import MediaUrlButton from '../shared/MediaUrlButton'
import MediaEditModal from '../shared/MediaEditModal'
import VideoListModal from '../video/VideoListModal'
import YoutubeModal from './richtext/YoutubeModal'
import SportTableModal from './richtext/SportTableModal'
import GalleryLinkModal from './richtext/GalleryLinkModal'
import SportOnlineModal from './richtext/SportOnlineModal'
import ArticleTestModal from './ArticleTestModal'
import EditorQuoteModal from './richtext/EditorQuoteModal'
import EditorHtmlModal from './richtext/EditorHtmlModal'
import InfoboxCreateModal from './richtext/InfoboxCreateModal'
import InfoboxListModal from './richtext/InfoboxListModal'
import PollCreateModal from './richtext/PollCreateModal'
import PollListModal from './richtext/PollListModal'
import RelatedArticleModal from './ArticleRelatedModal'
import RelatedArticleConfigModal from './richtext/RelatedArticleConfigModal'
import { ARTICLE_TYPE_DEFAULT } from '../mixins/valueObject/ArticleTypeMixin'
import ArticleTestDiscriminatorsMixin from '../mixins/valueObject/ArticleTestDiscriminatorsMixin'

export default {
  name: 'ArticleRichTextEditor',
  mixins: [ArticleTestDiscriminatorsMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    htmlClass: {
      default: '',
      type: String
    },
    value: {
      default: ''
    },
    siteName: {
      type: String,
      default: ''
    },
    articleType: {
      type: String,
      default: ARTICLE_TYPE_DEFAULT
    },
    plugins: {
      default: function () {
        return [
          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample'
        ]
      },
      type: Array
    },
    toolbar1: {
      default: 'formatselect | bold italic  strikethrough  forecolor backcolor | link | ' +
        'alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
      type: String
    },
    toolbar2: {
      default: '',
      type: String
    },
    otherOptions: {
      type: Object,
      default: function () {
        return {}
      }
    },
    enabledEmbedButtons: {
      type: Object,
      default: function () {
        return {
          media: true,
          ooyala: true,
          youtube: true,
          quote: true,
          poll: true,
          relatedArticle: true,
          relatedDiseaseArticle: false,
          html: true,
          pageBreak: true,
          paidContentBreak: false,
          infobox: true,
          galleryLink: true,
          sportTable: true,
          sportOnline: true,
          carTable: true
        }
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: '',
      editor: null,
      cTinyMce: null,
      checkerTimeout: null,
      isTyping: false,
      mediaEditModal: false,
      videoListModal: false,
      youtubeModal: false,
      sportTableModal: false,
      galleryLinkModal: false,
      sportOnlineModal: false,
      carTableModal: false,
      editorQuoteModal: false,
      editorHtmlModal: false,
      relatedArticleModal: false,
      relatedArticleConfigModal: false,
      relatedArticleModalMultiple: true,
      infoboxCreateModal: false,
      infoboxListModal: false,
      pollCreateModal: false,
      pollListModal: false,
      activeElement: null,
      selectedData: null,
      mediaSelectMultiple: true,
      pageBreaks: false,
      mediaToEdit: null,
      editorError: false,
      editorClasses: ''
    }
  },
  computed: {
    media () {
      return this.$store.getters['media/selectedGalleryMedia']
    }
  },
  components: {
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditModal: MediaEditModal,
    appVideoListModal: VideoListModal,
    appYoutubeModal: YoutubeModal,
    appSportTableModal: SportTableModal,
    appGalleryLinkModal: GalleryLinkModal,
    appSportOnlineModal: SportOnlineModal,
    appEditorQuoteModal: EditorQuoteModal,
    appEditorHtmlModal: EditorHtmlModal,
    appInfoboxCreateModal: InfoboxCreateModal,
    appInfoboxListModal: InfoboxListModal,
    appPollCreateModal: PollCreateModal,
    appPollListModal: PollListModal,
    appRelatedArticleModal: RelatedArticleModal,
    appRelatedArticleConfigModal: RelatedArticleConfigModal,
    appArticleTestModal: ArticleTestModal
  },
  mounted () {
    this.content = this.value
    this.init()
  },
  beforeDestroy () {
    this.destroyTinyMce()
  },
  watch: {
    value: function (newValue) {
      if (!this.isTyping) {
        if (this.editor !== null) {
          this.editor.setContent(newValue)
          this.submitNewContent()
        } else {
          this.content = newValue
        }
      }
    },
    enabledEmbedButtons: {
      deep: true,
      handler: function () {
        this.resolveBodyEmbedsVisibility()
      }
    },
    readonly (value) {
      if (value) {
        this.editor.setMode('readonly')
      } else {
        this.editor.setMode('design')
      }
    }
  },
  methods: {
    init () {
      const options = {
        selector: '#' + this.id,
        skin: false,
        toolbar1: this.toolbar1,
        toolbar2: this.toolbar2,
        plugins: this.plugins
      }
      tinymce.init(this.concatAssciativeArrays(options, this.otherOptions)).then(function (editors) {
        if (typeof editors[0] === 'undefined') {
          this.editorError = true
        } else {
          this.editorError = false
          this.initEditor(editors[0])
          this.resolveBodyEmbedsVisibility()
        }
      }.bind(this))
    },
    reloadEditor () {
      this.destroyTinyMce()
      this.init()
    },
    destroyTinyMce () {
      try {
        tinymce.activeEditor.destroy()
      } catch (err) {
        console.log(err)
      }
    },
    resolveBodyEmbedsVisibility () {
      let disabled = Object.keys(this.enabledEmbedButtons).filter(key => this.enabledEmbedButtons[key] === false)
      disabled = disabled.map(item => 'editor-disable-embed-' + item)
      this.editorClasses = disabled.join(' ')
    },
    initEditor (editor) {
      this.editor = editor
      editor.on('KeyUp', (e) => {
        this.submitNewContent()
      })
      editor.on('Change', (e) => {
        if (this.editor.getContent() !== this.value) {
          this.submitNewContent()
        }
        this.$emit('editorChange', e)
      })
      editor.on('init', (e) => {
        editor.setContent(this.content)
        this.$emit('input', this.content)
      })
      if (this.readonly) {
        this.editor.setMode('readonly')
      } else {
        this.editor.setMode('design')
      }
      this.nmhCustomSettings()
      this.setStickyTollbar()
      this.$emit('editorInit', this.editor)
    },
    concatAssciativeArrays (array1, array2) {
      if (array2.length === 0) {
        return array1
      }
      if (array1.length === 0) {
        return array2
      }
      const dest = []
      for (const key in array1) {
        dest[key] = array1[key]
      }
      for (const key in array2) {
        dest[key] = array2[key]
      }
      return dest
    },
    submitNewContent () {
      this.isTyping = true
      if (this.checkerTimeout !== null) {
        clearTimeout(this.checkerTimeout)
      }
      this.checkerTimeout = setTimeout(() => {
        this.isTyping = false
      }, 300)

      this.$emit('input', this.editor.getContent())
    },
    nmhCustomSettings () {
      this.editor.setContent(this.value)
      TinyMceSnippet.addSnippetActions({
        showMediaSearchModal: this.showMediaSearchModal,
        showMediaUploadModal: this.showMediaUploadModal,
        showMediaUrlModal: this.showMediaUrlModal,
        showMediaEditModal: this.showMediaEditModal,
        showRelatedArticleModal: this.showRelatedArticleModal,
        showRelatedArticleConfigModal: this.showRelatedArticleConfigModal,
        showInfoboxCreateModal: this.showInfoboxCreateModal,
        showInfoboxListModal: this.showInfoboxListModal,
        showVideoListModal: this.showVideoListModal,
        showYoutubeModal: this.showYoutubeModal,
        showSportTableModal: this.showSportTableModal,
        showGalleryLinkModal: this.showGalleryLinkModal,
        showSportOnlineModal: this.showSportOnlineModal,
        showCarTableModal: this.showCarTableModal,
        showEditorQuoteModal: this.showEditorQuoteModal,
        showEditorHtmlModal: this.showEditorHtmlModal,
        showPollListModal: this.showPollListModal
      }, '#' + this.id + '_ifr')
    },
    setStickyTollbar () {
      const toolbars = document.querySelectorAll('.mce-top-part')
      toolbars.forEach(toolbar => toolbar.setAttribute('style', 'position: -webkit-sticky; position: sticky; top: 60px;'))
    },
    // MEDIA SEARCH MODAL
    showMediaSearchModal (activeElement) {
      // Disable multiple select media when image was selected
      if (activeElement[0].getAttribute('data-media-id') !== '') {
        this.mediaSelectMultiple = false
      } else {
        this.mediaSelectMultiple = true
      }
      this.activeElement = activeElement
      this.$children[0].$refs.mediaSelectButton.click()
    },
    addPageBreaks (pageBreaks) {
      this.pageBreaks = pageBreaks
    },
    // MEDIA UPLOAD MODAL
    showMediaUploadModal (activeElement) {
      this.activeElement = activeElement
      this.$children[1].$refs.mediaUploadButton.click()
    },
    // MEDIA EDIT MODAL
    showMediaEditModal (activeElement) {
      this.activeElement = activeElement
      const mediaId = activeElement[0].getAttribute('data-media-id')
      this.$store.dispatch('media/fetchOne', mediaId)
        .then(() => {
          this.mediaToEdit = [this.$store.getters['media/detail']]
          this.mediaEditModal = true
        })
    },
    updateMediaEmbed (media) {
      this.activeElement[0].parentNode.parentNode.getElementsByClassName('imageCaption')[0].innerText = media[0].imageCaption
      this.activeElement[0].parentNode.parentNode.getElementsByClassName('imageAuthor')[0].innerText = media[0].imageAttribution
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    // MEDIA URL MODAL
    showMediaUrlModal (activeElement) {
      this.activeElement = activeElement
      this.$children[2].$refs.mediaUrlButton.click()
    },
    addMedia (medias) {
      let imageSnippets = ''
      for (let index = 0; index < medias.length; index++) {
        imageSnippets = imageSnippets + TinyMceSnippet.image(medias[index])
        if (this.pageBreaks && index < (medias.length - 1)) {
          imageSnippets = imageSnippets + TinyMceSnippet.pageBreak()
        }
      }

      if (this.activeElement) {
        this.editor.selection.select(this.activeElement[0].parentNode.parentNode.parentNode.parentNode)
        this.editor.insertContent(imageSnippets)
        const elemSnippet = this.activeElement.closest('.snippet-cb')
        if (elemSnippet.length !== 0) {
          elemSnippet.remove()
        }
      } else {
        this.editor.insertContent(imageSnippets)
      }

      this.submitNewContent()
    },
    addMediaToBodyText (medias) {
      this.activeElement = null
      this.addMedia(medias)
    },
    // VIDEO LIST MODAL
    showVideoListModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = this.activeElement[0].getElementsByClassName('video-link')[0].innerText
      this.videoListModal = true
    },
    closeVideoListModal () {
      this.videoListModal = false
    },
    // YOUTUBE MODAL
    showYoutubeModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = this.activeElement[0].getElementsByClassName('video-link')[0].innerText
      this.youtubeModal = true
    },
    closeYoutubeModal () {
      this.youtubeModal = false
    },
    setOoyalaOrYoutubeVideo (data) {
      this.activeElement[0].getElementsByClassName('video-link')[0].innerText = data
      this.submitNewContent()
    },
    setVideo (video) {
      this.activeElement[0].getElementsByClassName('video-link')[0].innerText = video.id
      this.submitNewContent()
    },
    // SPORT TABLE MODAL
    showSportTableModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = {
        seasonId: this.activeElement[0].getElementsByClassName('season-id')[0].innerText,
        activeTab: this.activeElement[0].getElementsByClassName('active-tab')[0].innerText
      }
      this.sportTableModal = true
    },
    closeSportTableModal () {
      this.sportTableModal = false
    },
    setSportTableData (data) {
      this.activeElement[0].getElementsByClassName('season-id')[0].innerText = data.seasonId
      this.activeElement[0].getElementsByClassName('active-tab')[0].innerText = data.activeTab
      this.submitNewContent()
    },
    // GALLERY LINK MODAL
    showGalleryLinkModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = {
        pageNumber: this.activeElement[0].getElementsByClassName('page-number')[0].innerText,
        linkText: this.activeElement[0].getElementsByClassName('link-text')[0].innerText
      }
      this.galleryLinkModal = true
    },
    closeGalleryLinkModal () {
      this.galleryLinkModal = false
    },
    setGalleryLinkData (data) {
      this.activeElement[0].getElementsByClassName('page-number')[0].innerText = data.pageNumber
      this.activeElement[0].getElementsByClassName('link-text')[0].innerText = data.linkText
      this.submitNewContent()
    },
    // SPORT ONLINE MODAL
    showSportOnlineModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = {
        matchId: this.activeElement[0].getElementsByClassName('match-id')[0].innerText,
        category: this.activeElement[0].getElementsByClassName('match-category')[0].innerText
      }
      this.sportOnlineModal = true
    },
    closeSportOnlineModal () {
      this.sportOnlineModal = false
    },
    setSportOnlineData (data) {
      this.activeElement[0].getElementsByClassName('match-id')[0].innerText = data.matchId
      this.activeElement[0].getElementsByClassName('match-category')[0].innerText = data.category
      this.submitNewContent()
    },
    // CAR TABLE MODAL
    showCarTableModal (activeElement) {
      this.activeElement = activeElement
      const ids = this.activeElement[0].getElementsByClassName('car-ids')[0].innerText.trim()
      if (ids.length > 0) {
        this.selectedData = {
          ids: ids.split(',')
        }
      } else {
        this.selectedData = {
          ids: []
        }
      }
      this.carTableModal = true
    },
    closeCarTableModal () {
      this.carTableModal = false
    },
    setCarTableData (data) {
      data = data.map(test => test.id)
      this.activeElement[0].getElementsByClassName('car-ids')[0].innerText = data.join(',')
      this.submitNewContent()
    },
    // QUOTE MODAL
    showEditorQuoteModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = {
        title: this.activeElement[0].getElementsByClassName('quoted-text')[0].innerText,
        author: this.activeElement[0].getElementsByClassName('author-name')[0].innerText
      }
      this.editorQuoteModal = true
    },
    closeEditorQuoteModal () {
      this.editorQuoteModal = false
    },
    setQuote (data) {
      this.activeElement[0].getElementsByClassName('quoted-text')[0].innerText = data.title
      this.activeElement[0].getElementsByClassName('author-name')[0].innerText = data.author
      this.submitNewContent()
    },
    // HTML MODAL
    showEditorHtmlModal (activeElement) {
      this.activeElement = activeElement
      this.selectedData = this.activeElement[0].getElementsByClassName('embed-name')[0].getAttribute('data-embed-content')
      this.editorHtmlModal = true
    },
    closeEditorHtmlModal () {
      this.editorHtmlModal = false
    },
    setHtml (data) {
      this.activeElement[0].getElementsByClassName('embed-name')[0].setAttribute('data-embed-content', data)
      this.submitNewContent()
    },
    // RELATED ARTICLE MODAL
    showRelatedArticleModal (activeElement) {
      const relatedArticleId = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-id')
      if (relatedArticleId.length > 30) {
        this.relatedArticleModalMultiple = false
      } else {
        this.relatedArticleModalMultiple = true
      }
      this.activeElement = activeElement
      this.relatedArticleModal = true
    },
    closeRelatedArticleModal () {
      this.relatedArticleModal = false
    },
    setRelatedArticle (relatedArticles) {
      const relatedArticleId = this.activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-id')
      if (relatedArticleId !== '') {
        this.updateRelatedArticle(relatedArticles)
      } else {
        this.insertRelatedArticles(relatedArticles)
      }
    },
    updateRelatedArticle (relatedArticles) {
      relatedArticles.forEach(article => {
        this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-article-id', article.documentId)
        this.activeElement[0].getElementsByClassName('inner')[0].innerText = article.field.title
      })
      this.submitNewContent()
    },
    insertRelatedArticles (relatedArticles) {
      let relatedArticleSnippets = ''
      relatedArticles.forEach(article => {
        relatedArticleSnippets = relatedArticleSnippets + TinyMceSnippet.relatedArticle(article)
      })
      this.editor.selection.select(this.activeElement[0])
      this.editor.insertContent(relatedArticleSnippets)

      const elemSnippet = this.activeElement.closest('.snippet-cb')
      if (elemSnippet.length !== 0) {
        elemSnippet.remove()
      }

      this.submitNewContent()
    },
    // RELATED ARTICLE CONFIG MODAL
    showRelatedArticleConfigModal (activeElement) {
      const relatedArticleTag = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-tag')
      this.selectedData = {
        tag: relatedArticleTag
      }
      this.activeElement = activeElement
      this.relatedArticleConfigModal = true
    },
    closeRelatedArticleConfigModal () {
      this.relatedArticleConfigModal = false
    },
    setRelatedArticleConfig (data) {
      this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-article-tag', data)
      this.activeElement[0].getElementsByClassName('inner-articleTag')[0].innerText = data
      if (data.length) {
        this.activeElement[0].getElementsByClassName('inner-break')[0].classList.remove('hide')
      } else {
        this.activeElement[0].getElementsByClassName('inner-break')[0].classList.add('hide')
      }
      this.submitNewContent()
    },
    // RELATED DISEASE ARTICLE MODAL
    showRelatedDiseaseArticleModal (activeElement) {
      const relatedDiseaseArticleId = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-id')
      if (relatedDiseaseArticleId.length > 30) {
        this.relatedDiseaseArticleModalMultiple = false
      } else {
        this.relatedDiseaseArticleModalMultiple = true
      }
      this.activeElement = activeElement
      this.relatedDiseaseArticleModal = true
    },
    closeRelatedDiseaseArticleModal () {
      this.relatedDiseaseArticleModal = false
    },
    setRelatedDiseaseArticle (relatedDiseaseArticles) {
      const relatedDiseaseArticleId = this.activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-id')
      if (relatedDiseaseArticleId !== '') {
        this.updateRelatedDiseaseArticle(relatedDiseaseArticles)
      } else {
        this.insertRelatedDiseaseArticles(relatedDiseaseArticles)
      }
    },
    updateRelatedDiseaseArticle (relatedDiseaseArticles) {
      relatedDiseaseArticles.forEach(article => {
        this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-article-id', article.documentId)
        this.activeElement[0].getElementsByClassName('inner')[0].innerText = article.field.title
      })
      this.submitNewContent()
    },
    insertRelatedDiseaseArticles (relatedDiseaseArticles) {
      let relatedDiseaseArticleSnippets = ''
      relatedDiseaseArticles.forEach(article => {
        relatedDiseaseArticleSnippets = relatedDiseaseArticleSnippets + TinyMceSnippet.relatedDiseaseArticle(article)
      })
      this.editor.selection.select(this.activeElement[0])
      this.editor.insertContent(relatedDiseaseArticleSnippets)

      const elemSnippet = this.activeElement.closest('.snippet-cb')
      if (elemSnippet.length !== 0) {
        elemSnippet.remove()
      }

      this.submitNewContent()
    },
    // RELATED ARTICLE CONFIG MODAL
    showRelatedDiseaseArticleConfigModal (activeElement) {
      const relatedDiseaseArticleTag = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-article-tag')
      this.selectedData = {
        tag: relatedDiseaseArticleTag
      }
      this.activeElement = activeElement
      this.relatedDiseaseArticleConfigModal = true
    },
    closeRelatedDiseaseArticleConfigModal () {
      this.relatedDiseaseArticleConfigModal = false
    },
    setRelatedDiseaseArticleConfig (data) {
      this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-article-tag', data)
      this.activeElement[0].getElementsByClassName('inner-articleTag')[0].innerText = data
      if (data.length) {
        this.activeElement[0].getElementsByClassName('inner-break')[0].classList.remove('hide')
      } else {
        this.activeElement[0].getElementsByClassName('inner-break')[0].classList.add('hide')
      }
      this.submitNewContent()
    },
    // INFOBOX MODAL
    showInfoboxCreateModal () {
      this.infoboxCreateModal = true
      this.infoboxListModal = false
    },
    closeInfoboxCreateModal () {
      this.infoboxCreateModal = false
    },
    showInfoboxListModal (activeElement = this.activeElement) {
      this.activeElement = activeElement
      const infoboxId = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-infobox-id')
      if (infoboxId) {
        this.$store.commit('infobox/setFilter', { id: infoboxId })
        this.$store.commit('infobox/setPage', 1)
      }
      this.infoboxListModal = true
      this.infoboxCreateModal = false
    },
    closeInfoboxListModal () {
      this.infoboxListModal = false
    },
    setInfobox (infobox) {
      this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-infobox-id', infobox.id)
      this.activeElement[0].getElementsByClassName('inner')[0].innerText = infobox.title
      this.submitNewContent()
    },
    // POLL MODAL
    showPollCreateModal () {
      this.pollCreateModal = true
      this.pollListModal = false
    },
    closePollCreateModal () {
      this.pollCreateModal = false
    },
    showPollListModal (activeElement = this.activeElement) {
      this.activeElement = activeElement
      const pollId = activeElement[0].getElementsByClassName('inner')[0].getAttribute('data-poll-id')
      if (pollId) {
        this.$store.commit('poll/setFilter', { id: pollId, type: 'poll' })
        this.$store.commit('poll/setPage', 1)
      } else {
        this.$store.commit('poll/setFilter', { id: '', type: 'poll' })
        this.$store.commit('poll/setPage', 1)
      }
      this.pollListModal = true
      this.pollCreateModal = false
    },
    closePollListModal () {
      this.pollListModal = false
    },
    setPoll (poll) {
      this.activeElement[0].getElementsByClassName('inner')[0].setAttribute('data-poll-id', poll.id)
      this.activeElement[0].getElementsByClassName('inner')[0].innerText = poll.title
      this.submitNewContent()
    }
  }
}
</script>

<style lang="scss">

.mce-top-part-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.mce-ico.mdi-format-header-2,
.mce-ico.mdi-format-header-3 {
  font-size: 18px;
}
</style>

<template>
  <section>
    <div class="title-text">
      {{ $t('recipe.photo_or_video') }}
    </div>
    <div>
      <button
        class="media-button"
        :class="{'media-button--active': !mainVideoBox }"
        @click="hideMainVideoBox"
      >
        <i class="fas fa-images"></i> {{ $t('media.main_image') }}
      </button>
      <button
        class="media-button"
        :class="{'media-button--active': mainVideoBox }"
        @click="showMainVideoBox"
      >
        <i class="fas fa-video"></i> {{ $t('media.main_video') }}
      </button>
    </div>
    <ModuleFormPhotoBox
      v-if="!mainVideoBox"
      key="photo"
      :image="recipe.expanded.image"
      :disabled="disabled"
      @set-media="setImage"
      @remove-media="removeImage"
      show-add-media-to-gallery
      @add-media-to-gallery="addMediaToGallery"
    />
    <ModuleFormPhotoBox
      v-else
      key="video"
      :image="recipe.expanded.image"
      :disabled="disabled"
      :select-click="() => $refs.videoSelectButton.showVideoListModal()"
      :select-text="$t('media.select_video')"
      @remove-media="removeVideo"
    >
      <template #buttons>
        <VideoSelectButton
          ref="videoSelectButton"
          @set-video="setVideo"
          :selected-dam-media="recipe.expanded.image"
          data-test="recipe_btn_select_video_from_dam"
        />
        <YoutubeSelectButton
          @set-video="setYoutubeVideo"
          :selected-dam-media="recipe.expanded.image"
          data-test="recipe_btn_select_video_from_dam"
        />
      </template>
    </ModuleFormPhotoBox>
    <!-- hide till CMS-541 is resolved -->
    <OutlinedCheckbox
      v-if="false"
      v-model="recipe.enabled"
      id="recipe_enabled"
      :label="$t('recipe.enabled')"
      :disabled="disabled"
    />
    <!-- hide till CMS-541 is resolved -->
    <OutlinedCheckbox
      v-if="false"
      v-model="recipe.unpublished"
      id="recipe_setUnpublished"
      :label="$t('media.set_unpublished')"
      :disabled="disabled"
    />
  </section>
</template>

<script>

import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import MediaService from '@/services/media/MediaService'
import YoutubeSelectButton from '@/components/shared/YoutubeSelectButton'
import VideoSelectButton from '@/components/shared/VideoSelectButton'
import NotifyService from '@/services/NotifyService'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'

export default {
  name: 'RecipeFormMainTabRightColumn',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OutlinedCheckbox,
    ModuleFormPhotoBox,
    YoutubeSelectButton,
    VideoSelectButton
  },
  data () {
    return {
      mainVideoBox: false
    }
  },
  methods: {
    showMainVideoBox () {
      this.mainVideoBox = true
    },
    hideMainVideoBox () {
      this.mainVideoBox = false
    },
    setImage (medias) {
      medias.forEach(media => {
        this.recipe.expanded.image = media
      })
    },
    removeImage () {
      this.recipe.expanded.image = null
    },
    checkIfAssetExistInGallery (asset) {
      let selected = false
      this.recipe.expanded.gallery.forEach(media => {
        if (media.damMediaEmbed.damId === asset.damMediaEmbed.damId) {
          selected = true
        }
      })
      return selected
    },
    addMediaToGallery (medias) {
      medias.forEach(media => {
        if (this.checkIfAssetExistInGallery(media) === false) {
          MediaService.createMedia(media)
            .then(newMedia => {
              this.recipe.expanded.gallery.push(newMedia)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    setImageFromGallery (medias) {
      medias.forEach(media => {
        MediaService.createMedia(media)
          .then(newMedia => {
            this.setImage([newMedia])
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    removeVideo () {
      this.recipe.video = ''
      this.recipe.videoType = 'none'
      this.recipe.image = null
      this.recipe.expanded.image = null
    },
    setVideo (video) {
      this.recipe.video = video.id
      this.recipe.videoType = 'livebox'
      this.setImageFromGallery([video.expanded.previewImage])
    },
    setYoutubeVideo (youtubeId) {
      this.recipe.video = youtubeId
      this.recipe.videoType = 'youtube'
      MediaService.createMediaFromUrl('https://i.ytimg.com/vi/' + youtubeId + '/hqdefault.jpg')
        .then(response => {
          this.setImage([response.data])
        })
        .catch((error) => {
          NotifyService.setErrorMessage(this.$t('article.notify.youtube_thumbnail_error'))
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.media-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: rem(8px);
  margin-bottom: 1rem;
  margin-right: rem(8px);
  height: rem(30px);
  cursor: pointer;
  transition: background 200ms;
  position: relative;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
      color: #FFFFFF !important;
      &:after {
        border-top-color: darken(#6599fe, 10%);
      }
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
  &:not(.media-button--active) {
    border: solid #8A96AC rem(1px);
    color: #465674;
  }
  &--active {
    color: #FFFFFF;
    background: #6599FE;
    border: solid #6599FE rem(1px);
    &:after {
      transition: border-top-color 200ms;
      content: '';
      position: absolute;
      left: calc(50% - 5px);
      top: 100%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #6599FE;
    }
  }
}
</style>

<template>
  <app-modal
    :title="$t('article.modal.html_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ $t('article.modal.html_code') }}</label>
            <textarea
              v-model="value"
              type="text"
              class="form-control"
              :placeholder="$t('article.modal.html_code_placeholder')"
              rows="10"
            >
            </textarea>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import CoreApi from '@/api/core'
import Modal from '@/components/shared/Modal'

export default {
  name: 'EditorHtmlModal',
  data () {
    return {
      value: ''
    }
  },
  props: {
    selectedData: {
      type: String,
      default: ''
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    decode () {
      CoreApi().post('/utils/decode', JSON.stringify({ input: this.selectedData }))
        .then(response => {
          this.value = response.data.decoded
        })
        .catch(error => {
          console.log(error)
        })
    },
    encode () {
      CoreApi().post('/utils/encode', JSON.stringify({ input: this.value }))
        .then(response => {
          this.$emit('set-data', response.data.encoded)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
        })
    },
    insert () {
      this.$emit('set-data', this.base64EncodeUnicode(this.value))
      this.$emit('close')
    },
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    },
    base64EncodeUnicode (str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes (match, p1) {
        return String.fromCharCode('0x' + p1)
      }))
    }
  },
  created () {
    if (this.selectedData) {
      this.decode()
    }
  }
}
</script>

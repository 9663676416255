<template>
    <span>
        <button
          type="button"
          class="btn btn-green"
          :disabled="disabledCreateRecipeArticleButton"
          @click="create"
        >
            {{ $t('recipe.create_article') }}
        </button>
        <app-recipe-create-article-modal
          v-if="alertModal"
          @close="closeAlertModal"
          @create="createRecipeArticle"
        >
        </app-recipe-create-article-modal>
    </span>
</template>

<script>
import NotifyService from '../../services/NotifyService'
import MediaService from '../../services/media/MediaService'
import RecipeCreateArticleModal from './RecipeCreateArticleModal'

const RECIPE_ARTICLE_DEFAULT_SITE = 1

export default {
  name: 'RecipeCreateArticleButton',
  props: {
    recipe: {
      type: Object
    }
  },
  data () {
    return {
      alertModal: false,
      disabledCreateRecipeArticleButton: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  components: {
    appRecipeCreateArticleModal: RecipeCreateArticleModal
  },
  methods: {
    getDefaultSiteId () {
      if (this.currentUser.defaultSite !== null) {
        return this.currentUser.defaultSite
      }
      if (this.currentUser.sites.length > 0) {
        return this.currentUser.sites[0]
      }
      return RECIPE_ARTICLE_DEFAULT_SITE
    },
    async getDefaultRubricId () {
      if (this.currentUser.defaultRubric !== null) {
        return this.currentUser.defaultRubric
      }
      const defaultSiteId = this.getDefaultSiteId()
      // this is fetching only rubrics that are not fetched yet
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', defaultSiteId)
      const rubrics = this.$store.getters['rubric/all']
      const rubric = rubrics.find(rubric => rubric.site === defaultSiteId)
      return rubric.id
    },
    async mapRecipeToArticle (newImage) {
      return {
        site: this.getDefaultSiteId(),
        mainRubric: await this.getDefaultRubricId(),
        type: 'recipe',
        recipe: this.recipe.id,
        field: {
          title: this.recipe.title,
          shortTitle: this.recipe.title,
          subTitle: this.recipe.title,
          firstParagraph: this.recipe.description,
          video: this.recipe.video,
          videoType: this.recipe.videoType
        },
        flag: {
          recipe: true
        },
        expanded: {
          heroImage: newImage
        }
      }
    },
    create () {
      if (this.recipe.id !== null) {
        this.createRecipeArticle()
      } else {
        this.showAlertModal()
      }
    },
    async createRecipeArticle () {
      if (this.recipe.id === null) {
        this.$emit('save')
      }
      this.disabledCreateRecipeArticleButton = true
      if (this.recipe.expanded.image) {
        MediaService.createMedia(this.recipe.expanded.image)
          .then(async (media) => {
            this.createArticle(await this.mapRecipeToArticle(media))
          })
          .catch(() => {
            NotifyService.setErrorMessage(this.$t('recipe.article_created_error'))
          })
      } else {
        this.createArticle(await this.mapRecipeToArticle({}))
      }
    },
    createArticle (article) {
      this.$store.dispatch('article/create', article)
        .then(() => {
          this.disabledCreateRecipeArticleButton = false
          if (this.$store.getters['article/error']) {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
            return
          }
          const newArticle = this.$store.getters['article/fullArticle']
          NotifyService.setSuccessMessage(this.$t('recipe.article_created'))
          if (newArticle.id > 0) {
            this.$router.push('/article/' + newArticle.id + '/edit')
          }
        })
        .catch(error => {
          this.disabledCreateRecipeArticleButton = false
          console.log(error)
        })
    },
    showAlertModal () {
      this.alertModal = true
    },
    closeAlertModal () {
      this.alertModal = false
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
  <app-modal
    :title="$t('article.modal.youtube_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ $t('article.modal.youtube_url') }}</label>
            <input
              v-model.trim="value"
              type="text"
              class="form-control"
            >
          </div>
          <div class="form-group text-center" v-if="dataLoaded && preview.status === 200">
            {{ preview.title }}
            <div v-html="preview.html"></div>
          </div>
          <div class="form-group text-center" v-if="dataLoaded">
            <p class="alert alert-warning" v-if="preview.status && preview.status !== 200">
              {{ $t('article.modal.youtube_url_warning') }}
            </p>
          </div>
          <div class="form-group text-center" v-if="!dataLoaded">
            <app-preloader></app-preloader>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button
              v-if="showInsertButton"
              @click="insert"
              type="button"
              class="btn btn-green"
            >
              {{ $t('article.modal.insert') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import CoreApi from '../../../api/core'
import Preloader from '../../preloader/Preloader'
import Modal from '../../shared/Modal'

export default {
  name: 'YoutubeModal',
  props: {
    selectedData: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: '',
      preview: {},
      message: '',
      dataLoaded: false,
      showInsertButton: false,
      youtubeTimeout: null
    }
  },
  watch: {
    value () {
      clearTimeout(this.youtubeTimeout)
      this.showInsertButton = false
      this.youtubeTimeout = setTimeout(() => {
        this.getVideo()
      }, 300)
    }
  },
  components: {
    appModal: Modal,
    appPreloader: Preloader
  },
  methods: {
    getVideo () {
      this.dataLoaded = false
      setTimeout(() => {
        CoreApi().post('/utils/youtube', JSON.stringify({ input: this.parseUrl(this.value) }))
          .then(response => {
            this.preview = response.data.youtube
            this.dataLoaded = true
            this.showInsertButton = true
          })
          .catch(error => {
            this.dataLoaded = true
            console.log(error)
          })
      }, 500)
    },
    parseUrl (youtubeUrl) {
      try {
        const url = new URL(youtubeUrl)
        const videoId = url.searchParams.get('v')
        if (videoId === undefined) {
          return url
        }
        this.value = videoId
        return videoId
      } catch (error) {
        return youtubeUrl
      }
    },
    insert () {
      this.$emit('set-data', this.value)
      this.$emit('close')
    },
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    }
  },
  created () {
    if (this.selectedData.length > 5) {
      this.value = this.selectedData
      this.getVideo()
    } else {
      this.dataLoaded = true
    }
  }
}
</script>

<style>
</style>

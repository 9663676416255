<template>
  <app-modal
    :title="$t('article.modal.related_articles_header_tag')"
    modalSize="modal-md eagle-related-article-tag-modal"
    @close="close"
  >
    <template slot="body">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <app-advanced-input
            v-model.trim="tag"
            @blur="$v.tag.$touch()"
            :error="$v.tag.$error"
            id="related_article_tag"
            noLabel
            showActions
            :actions="tagFillActions"
            @input-action-clicked="preFillTag($event)"
            showButtons
            :buttons="inputDeleteButtons"
            @input-button-clicked="clearTag()"
            first-actions-or-buttons="actions"
          >
          </app-advanced-input>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="input-group-btn text-center">
        <button @click="setTagAndClose" type="button" class="btn btn-green">{{ $t('buttons.save') }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { maxLength, minLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import AdvancedInput from '../../form/inputs/InputAdvanced'
import Modal from '../../shared/Modal'

export default {
  name: 'RelatedArticleConfigModal',
  props: {
    selectedData: {
      type: Object
    }
  },
  data () {
    return {
      tag: '',
      inputDeleteButtons: [{ class: 'btn btn-danger', html: '<i class="fas fa-trash"></i>' }],
      tagFillActions: [
        this.$t('article.readAlso'),
        this.$t('article.commentOnTheTopic'),
        this.$t('article.comment'),
        this.$t('article.relatedArticle'),
        this.$t('article.review'),
        this.$t('article.test'),
        this.$t('article.moreOnTheTopic')
      ]
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    appModal: Modal,
    appAdvancedInput: AdvancedInput
  },
  validations: {
    tag: {
      minLength: minLength(1),
      maxLength: maxLength(30)
    }
  },
  methods: {
    setTagAndClose () {
      this.$emit('set-data', this.tag)
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    },
    preFillTag (actionIndex) {
      this.tag = this.tagFillActions[actionIndex]
    },
    clearTag () {
      this.tag = ''
    }
  },
  created () {
    if (this.selectedData && this.selectedData.tag && this.selectedData.tag.length > 0) {
      this.tag = this.selectedData.tag
    }
  }
}
</script>

<style lang="scss">
.eagle-related-article-tag-modal {
  .modal-footer {
    justify-content: center;
  }

  .btn-danger, .btn-danger.disabled {
    box-shadow: none;
  }
}
</style>

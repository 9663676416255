<template>
  <app-modal
    :title="$t('article.modal.sport_online_header')"
    @close="close"
  >
    <!-- Body -->
    <template v-if="categoriesLoaded" slot="body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ $t('article.modal.sport_online_matchId') }}</label>
            <input type="text" v-model.trim="value.matchId" class="form-control">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-select
            v-model="value.category"
            :options="categories"
            id="sport_online_categories"
            :label="$t('article.modal.sport_online_category')"
            no-empty-value
          >
          </app-select>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../../shared/Modal'
import CoreApi from '../../../api/core'
import Select from '../../form/select/Select'

export default {
  name: 'SportOnlineModal.',
  data () {
    return {
      value: {
        matchId: '',
        category: 'other'
      },
      categories: [],
      categoriesLoaded: false
    }
  },
  props: {
    selectedData: {
      type: Object
    }
  },
  components: {
    appModal: Modal,
    appSelect: Select
  },
  methods: {
    insert () {
      this.$emit('set-data', { matchId: this.value.matchId, category: this.value.category })
      this.$emit('close')
    },
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    },
    getSportOnlineCategories () {
      CoreApi().get('/sportOnlineCategory')
        .then(response => {
          Object.keys(response.data).forEach(id => {
            this.categories.push({ id: id, title: response.data[id] })
          })
          this.categoriesLoaded = true
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getSportOnlineCategories()
    if (this.selectedData) {
      this.value = this.selectedData
    }
  }
}
</script>

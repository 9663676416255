<template>
  <section>
    <div class="main-heading-text">
      {{ $t('recipe.allergens') }}
    </div>
    <div class="form-group">
      <div class="title-text">
        {{ $t('recipe.allergens') }}
      </div>
      <MultiSelect
        v-model="recipe.allergens"
        :options="allergens"
        label="title"
        track-by="allergen"
        :loading="callingAPI"
        :internal-search="false"
        :options-limit="300"
        :limit="20"
        :max-height="600"
        :show-no-results="false"
        open-direction="bottom"
        id="recipe_allergens"
        :disabled="disabled"
      />
    </div>
  </section>
</template>

<script>
import MultiSelect from '@/components/form/select/MultiSelect'
import { mapState } from 'vuex'

export default {
  name: 'RecipeFormAllergensRightColumn',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MultiSelect
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    allergens () {
      return this.$store.getters['recipe/allergens']
    }
  },
  mounted () {
    this.$store.dispatch('recipe/fetchAllergens')
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>

<template>
  <ModuleForm
    ref="recipeForm"
    :module-name="$t('moduleForm.recipe')"
    :module="recipe"
    :show-header-info="disabled"
    :tabs="tabs"
    @tab-change="(index) => tabIndex = index"
  >
    <template #header-buttons>
      <RecipeCreateArticleButton
        v-if="recipe.id"
        :recipe="recipe"
        @save="save"
      />
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ButtonDelete
        v-if="recipe.id"
        :tooltip="$t('recipe.article_exists')"
        :disabled="recipeArticles.length > 0"
        @deleteRecord="remove"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/recipe')"
      />
    </template>
    <template #form-content>
      <div
        v-show="tabIndex === 0 || tabIndex === 2"
        class="form-content"
      >
        <div class="form-content__left">
          <RecipeFormMainTabLeftColumn
            v-show="tabIndex === 0"
            ref="mainLeftColumn"
            :recipe="recipe"
            :disabled="disabled"
          />
          <RecipeFormIngredientsLeftColumn
            v-show="tabIndex === 2"
            ref="ingredientsLeftColumn"
            :recipe="recipe"
            :disabled="disabled"
          />
        </div>
        <div class="form-content__separator">
          <div class="form-content__separator__line"/>
        </div>
        <div class="form-content__right">
          <div v-show="tabIndex === 0">
            <RecipeFormMainTabRightColumn
              ref="mainRightColumn"
              :recipe="recipe"
              :disabled="disabled"
            />
            <div v-if="recipe.id !== null" class="used-in-articles">
              <UsedInArticlesTable
                filter="recipe"
                :id="recipe.id"
                @set-data="setRecipeArticles"
              />
            </div>
          </div>
          <RecipeFormAllergensRightColumn
            v-show="tabIndex === 2"
            ref="allergensRightColumn"
            :recipe="recipe"
            :disabled="disabled"
          />
        </div>
      </div>
      <!-- keep 'v-if' as RecipeFormInstructionsTab needs to render with tab-change
           because 'v-show' doesn't work with tinymce properly -->
      <div v-if="tabIndex === 1">
        <RecipeFormInstructionsTab
          class="instructions-tab"
          ref="instructionsTab"
          :recipe="recipe"
          :disabled="disabled"
        />
      </div>
      <div v-if="tabIndex === 3">
        <RecipeFormGalleryTab
          ref="galleryTab"
          :recipe="recipe"
          :disabled="disabled"
        />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import NotifyService from '@/services/NotifyService'
import ButtonDelete from '@/components/shared/ButtonDelete'
import UsedInArticlesTable from '@/components/article/UsedInArticlesTable'
import RecipeCreateArticleButton from '@/components/recipe/RecipeCreateArticleButton'
import ParserOnSave from '@/services/tinymce/ParserOnSave'
import RecipeFormMainTabLeftColumn from '@/components/recipe/form/RecipeFormMainTabLeftColumn'
import RecipeFormIngredientsLeftColumn from '@/components/recipe/form/RecipeFormIngredientsTabLeftColumn'
import RecipeFormMainTabRightColumn from '@/components/recipe/form/RecipeFormMainTabRightColumn'
import RecipeFormAllergensRightColumn from '@/components/recipe/form/RecipeFormAllergensTabRightColumn'
import RecipeFormInstructionsTab from '@/components/recipe/form/RecipeFormInstructionsTab'
import RecipeFormGalleryTab from '@/components/recipe/form/RecipeFormGalleryTab'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import RecipeModel from '@/model/RecipeModel'

export default {
  name: 'RecipeNewView',
  components: {
    ModuleFormButton,
    ModuleForm,
    RecipeFormGalleryTab,
    RecipeFormInstructionsTab,
    RecipeFormAllergensRightColumn,
    RecipeFormMainTabRightColumn,
    RecipeFormIngredientsLeftColumn,
    RecipeFormMainTabLeftColumn,
    ButtonDelete,
    UsedInArticlesTable,
    RecipeCreateArticleButton
  },
  data () {
    return {
      tabs: [
        { name: this.$t('recipe.main_tab'), error: false },
        { name: this.$t('recipe.instructions_tab'), error: false },
        { name: this.$t('recipe.ingredients_tab'), error: false },
        { name: this.$t('recipe.gallery_tab'), error: false }
      ],
      tabIndex: 0,
      STEP_BREAK: '\n###BREAK###\n',
      recipeArticles: []
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    recipe () {
      return this.$store.getters['recipe/detail']
    }
  },
  methods: {
    async getRecipe () {
      await this.$store.dispatch('recipe/fetchOne', this.$route.params.id)
      if (this.recipe.videoType !== 'none') {
        this.$refs.mainRightColumn.showMainVideoBox()
      }
    },
    isFormInvalid () {
      const formRefs = [this.$refs.mainLeftColumn, this.$refs.mainRightColumn,
        this.$refs.instructionsTab,
        this.$refs.ingredientsLeftColumn, this.$refs.allergensRightColumn,
        this.$refs.galleryTab]
      let invalid = false
      formRefs.forEach((ref, idx) => {
        if (ref?.$v) {
          ref.$v.$touch()
          const tabIndex = [0, 1].includes(idx) ? 0 : idx === 2 ? 1 : [3, 4].includes(idx) ? 2 : 3
          this.tabs[tabIndex].error = ref.$v.$invalid
          invalid = invalid || ref.$v.$invalid
        }
      })
      return invalid
    },
    async save () {
      if (this.isFormInvalid()) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const instructions = await ParserOnSave.parseAll(this.recipe.instructions.join(this.STEP_BREAK))
      const recipe = {
        ...this.recipe,
        instructions
      }
      const action = this.recipe.id ? 'update' : 'create'
      const successNotify = this.recipe.id ? 'record_was_updated' : 'record_was_created'
      try {
        await this.$store.dispatch(`recipe/${action}`, recipe)
        NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
        this.recipe.id = this.$store.getters['recipe/detail'].id
        this.goToEdit()
      } catch (err) {
        NotifyService.setErrorMessage(this.$store.getters['recipe/error'])
      }
    },
    async remove () {
      try {
        await this.$store.dispatch('recipe/deleteRecord', this.recipe)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
        this.$router.push('/recipe')
      } catch (err) {
        NotifyService.setErrorMessage(this.$store.getters['recipe/error'])
      }
    },
    setRecipeArticles (articles) {
      this.recipeArticles = articles
    },
    goToEdit () {
      const path = '/recipe/' + this.recipe.id + '/edit'
      if (this.$router.currentRoute.path === path) {
        // reloads recipe with all its questions
        this.getRecipe()
      } else {
        this.$store.commit('recipe/setPreviousTabIndex', this.tabIndex)
        this.$router.push(path)
      }
    }
  },
  mounted () {
    this.$store.commit('recipe/storeDetail', this._.cloneDeep(RecipeModel))
  }
}
</script>

<style scoped lang="scss">
.instructions-tab {
  @include bp(0 7) {
    width: 100%;
  }
  @include bp(11) {
    width: 75%;
  }
}
.used-in-articles {
  margin-top: 1rem;
}
</style>
